<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Product</label>
                        <vField
                            v-model="data.product"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                      <AsyncSelect
                          placeholder="Select Product"
                          v-model="data.product"
                          :api-service="fetchProductList"
                          :format-label="option => option.text"
                          :additional-query="additionalQuery"
                          label="text"
                          :disabled="isDisable"
                      />
                    </div>
                </div>
                <div v-else class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Account Heads</label>
                        <vField
                            v-model="data.account_head_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                            @change="onChangeAmountQuantity(data, $event.target.value)"
                        />
                        <v-select
                            placeholder="Select Head"
                            v-model="data.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                            :disabled="isDisable"
                        />
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="currency">Currency</label>
                        <vField
                                v-model="data.currency"
                                name="currency"
                                type="text"
                                class="form-control d-none"
                        />
                        <v-select
                                placeholder="Select Currency"
                                v-model="data.currency"
                                :options="currencies"
                                :reduce="name => name"
                                :disabled="isDisable"
                        />
                    </div>
                </div>
                <template v-if="isItem">
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="rate">Rate {{ purchaseRate ? '(' + purchaseRate + ')' : '' }}</label>
                            <vField
                                v-model="data.rate"
                                name="rate"
                                type="number"
                                class="form-control text-right"
                                :disabled="isDisable"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Quantity</label>
                            <vField
                                v-model="data.quantity"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                :disabled="isDisable"
                            />
                        </div>
                    </div>
                </template>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            readonly
                            v-model="amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            :disabled="isDisable"
                        />
                    </div>
                </div>

                <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            v-model="data.amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            :disabled="isDisable"
                        />
                    </div>
                </div>

              <div v-if="isItem" class="col-md-2 col-12">
                <div class="mb-1">
                  <label class="form-label" for="discount-percent">Discount Percentage (%)</label>
                  <vField
                      v-model="data.discount_percent"
                      name="discount-percent"
                      type="number"
                      class="form-control text-right"
                      max="100"
                      min="0"
                  />
                </div>
              </div>

              <div v-if="isItem" class="col-md-2 col-12">
                <div class="mb-1">
                  <label class="form-label" for="discount-amount">Discount Amount</label>
                  <vField
                      v-model="data.discount_amount"
                      name="discount-amount"
                      type="number"
                      class="form-control text-right"
                      min="0"
                  />
                </div>
              </div>
              <div v-if="isItem" class="col-md-2 col-12">
                <div class="mb-1">
                  <label class="form-label" for="quantity">Amount after discount</label>
                  <vField
                      v-model="amountAfterDiscount"
                      name="amount"
                      type="number"
                      class="form-control text-right"
                      readonly=""
                  />
                </div>
              </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat">VAT Rate</label>
                        <vField
                            v-model="data.vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select text-right"
                            :disabled="isDisable"
                        >
                            <option
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat_amount">VAT amount</label>
                        <vField
                            v-model="data.vat_amount"
                            name="vat_amount"
                            type="number"
                            class="form-control text-right"
                            :disabled="isDisable"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                            :disabled="isDisable"
                        />
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                         <vField
                            v-model="data.description"
                            style="height: 38px"
                            as="textarea"
                            name="description"
                            type="number"
                            class="form-control"
                            :disabled="isDisable"
                        />
                    </div>
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"
import { watch } from "vue";
import {useRoute} from "vue-router";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchaseAndSales from "@/services/modules/purchase";

const emit = defineEmits(['callConfirmLimit'])
let accountEditable = ref(0)
const route = useRoute()
const {fetchProductList, fetchProductStock} = handlePurchaseAndSales()

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  isPOCreate: Boolean,
  isPO: {
    type: Boolean,
    default: false
  },
  isDisable: {
    type: Boolean,
    default: true
  },
  index: Number,
  vatRate: Array,
  products: Array,
  currencies: Array,
  accountHeads: Array
})

const companyQuery = `?company_id=${route.params.companyId}`;
let purchaseRate = ref(null)
const discountPercent = computed(() => {
  return pr.data.discount_percent
})

const additionalQuery = ref({
  product_type: "finished_goods"
})

const amount = computed(() => {
    if(pr.isItem) {
        return (pr.data.rate * pr.data.quantity).toFixed(2)
    }
    if(!pr.isItem) {
        return pr.data.amount.toFixed(2)
    }
})

const vatAmount = computed(() => {
    if(pr.data.vat && pr.isItem) {
        return (pr.data.vat/100 * amountAfterDiscount.value).toFixed(2)
    }

    if(pr.data.vat) {
        return (pr.data.vat/100 * pr.data.amount).toFixed(2)
    }

    return 0;
})

const selectedProduct = computed(() => pr.data.product)

watch(selectedProduct, (newValue) => {
  if(newValue && newValue.id) {
    fetchProductStock(newValue.id, `${companyQuery}&skip_stock_check=1`).then((res) => {
      if(res.data) {
         purchaseRate.value = res.data.purchase_rate ?  res.data.purchase_rate : null;
         return;
      }
    })
   .catch(() => {})
  }
})

watch(discountPercent, (value) => {
  let discount = 0;
  value = parseFloat(value);
  if(value && value > 0 && amount.value > 0) {
    discount = amount.value * (value/100);
  }
  pr.data.discount_amount = discount.toFixed(2);
})

watch(amount, (value) => {
  if (value > 0 && discountPercent && discountPercent.value > 0) {
    pr.data.discount_amount = (value * (discountPercent.value/100)).toFixed(2)
    return ;
  }
  pr.data.discount_amount = 0;
})

const amountAfterDiscount = computed(() => {
  let discountAmount = pr.data.discount_amount === '' ? 0 : parseFloat(pr.data.discount_amount)
  discountAmount += pr.data.offer_discount && pr.data.offer_discount !== '' ? parseFloat(pr.data.offer_discount) : 0;

  if(discountAmount === 0) {
    return amount.value;
  }
  return (amount.value - discountAmount).toFixed(2)
})

watch(vatAmount, (newValue, oldValue) => {
  if(!oldValue && route.query.poId){
    pr.data.vat_amount  = pr.data.vat_amount;
  }else {
    pr.data.vat_amount  = newValue;
  }
})
const totalAmount = computed(() => {
    if(pr.data.vat == 0 && pr.data.vat_amount == 0 && pr.isItem) {
        return parseFloat(amountAfterDiscount.value).toFixed(2)
    }
    if(pr.data.vat == 0 && pr.data.vat_amount == 0) {
      return parseFloat(amount.value);
    }
    if(pr.isItem) {
        return (parseFloat(amountAfterDiscount.value) + parseFloat(pr.data.vat_amount)).toFixed(2)
    }
    return (parseFloat(pr.data.amount) + parseFloat(pr.data.vat_amount)).toFixed(2)
})
watch(amount, (value) => {
  pr.data.amount = amount.value;
})

</script>


<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

